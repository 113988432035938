import React from "react";

// import Swiper core and required modules
import { Autoplay, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Testimonial from "../components/Testimonial";
import Header from "../components/Header";

const Home = () => {
    return (
        <main>
            <Header />
            <img src="images/top-header.png" className="top-header-img" alt="logo protelkom"/>
            <div className="header-main-page-section">
                <div className="row-section">
                    <div className="container-50">
                        <img src="images/test.png" className="img-header" alt="logo protelkom"/>
                    </div>
                    
                    <div className="header-text">
                    <h1>Profesjonalny<br />Serwis - Sklep Telefonów</h1>
                    <p>Naprawa telefonów&thinsp;/&thinsp;smartfonów&thinsp;/&thinsp;tabletów oraz laptopów</p>
                    </div>
                </div>
                <img src="images/bttm-header.png" className="bttm-header-img" alt="logo protelkom"/>
            </div>
            <div className="dark-section section">
                <div className="with-u-section row-section">
                    <div className="with-u-text">
                        <h3>Jesteśmy z wami od {(new Date().getFullYear())-2005} lat!</h3>
                        <p>Dzięki wieloletniemu doświadczeniu w branży telekomunikacyjnej, doskonale wiemy, jak ważne jest prawidłowe zdiagnozowanie usterki oraz dobór odpowiednich części, aby naprawa spełniała standardy najwyższej jakości. {(new Date().getFullYear())-2005} lat doświadczenia w naprawach telefonów pozwala nam diagnozować usterki z niemal 100% skutecznością.</p>
                    </div>
                    <img src="images/DSC_6245.JPG" alt="stanowiska w protelkom" />
                </div>
                <div className="with-u-section row-section">
                    <div className="with-u-text">
                        <p>Zaufanie, którym obdarzają nas klienci przez lata, motywuje nas do ciągłego doskonalenia naszych usług. Dzięki nowoczesnym narzędziom oraz regularnym szkoleniom naszych techników, jesteśmy w stanie naprawiać zarówno najnowsze modele telefonów, jak i starsze urządzenia, zawsze zapewniając najwyższą jakość obsługi i pełne zadowolenie klienta.</p>
                    </div>
                    <img src="images/DSC_6236.JPG" alt="stanowiska w protelkom" />
                </div>
            </div>
            <div className="section">
                <div className="scroll-section row-section">
                    <h2 className="question-h2">
                        Dlaczego warto <br />wybrać PROtelkom<span>?</span>
                    </h2>
                    <div className="scroll-item-container">
                        <div className="scroll-item">
                            <h2 className="title-scroll-item">Doświadczenie i profesjonalizm</h2>
                            <p>Nasi technicy mają wieloletnie doświadczenie i szeroką wiedzę techniczną.</p>
                        </div>
                        <div className="scroll-item">
                            <h2 className="title-scroll-item">Indywidualne podejście</h2>
                            <p>Każdy klient jest dla nas ważny, dlatego oferujemy spersonalizowane rozwiązania dopasowane do Twoich potrzeb.</p>
                        </div>
                        <div className="scroll-item">
                            <h2 className="title-scroll-item">Szybkość i efektywność</h2>
                            <p>Staramy się realizować naprawy w możliwie najkrótszym czasie.</p>
                        </div>
                        <div className="scroll-item">
                            <h2 className="title-scroll-item">Gwarancja jakości</h2>
                            <p>Używamy oryginalnych lub wysokiej jakości części zamiennych i oferujemy gwarancję na wykonane usługi.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="red-section section">
                <h2 class="subtitle">Opinie naszych klientów</h2>
                <Swiper
                className="testimonials-swiper"
                // install Swiper modules
                modules={[Autoplay, Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}        
                loop={true}    
                autoplay={{delay:7000}}
                speed={3000}   
                
                breakpoints={{
                    // when window width is >= 768px
                    800: {
                    slidesPerView: 2,
                    },
                    1200: {
                    slidesPerView: 3,
                    },
                }}         
                onSwiper={(swiper) => console.log(swiper)}
                >
                    <SwiperSlide><Testimonial customerName="Magdalena O." customerFirstLetter="M" customerOpinion="Panowie bardzo pomocni i uprzejmi.
                                Choć mieli dużo zleceń, zrozumieli moją sytuację i przyjęli zlecenie.
                                Fachowość, uprzejmość i wyrozumiałość to rzadkość w usługach na rynku.
                                Polecono mi ten punkt i ja też go będę dalej polecać." /></SwiperSlide>
                    <SwiperSlide><Testimonial customerName="Adrian S." customerFirstLetter="A" customerOpinion="Szkoda, że tu tylko można 5 gwiazdek dać, bo serwis zasługuje na dużo więcej!
                            Polecam!" /></SwiperSlide>
                    <SwiperSlide><Testimonial customerName="Paweł B." customerFirstLetter="P" customerOpinion="Kolejna wizyta w tym miejscu i nadal wysoki poziom. Sprzęt, akcesoria tańsze od innych miejsc. Do każdego klienta podejście rewelacyjne, a jeżeli czegoś brak szybkie sprowadzenie. Profesjonalna pomoc w każdym przypadku bez naciągania. Sprzęt i akcesoria na każdą kieszeń. Tak trzymać 😎 godne polecenia…" /></SwiperSlide>
                    <SwiperSlide><Testimonial customerName="Stanisław P." customerFirstLetter="S" customerOpinion="Szybko, na miejscu i profesjonalnie. Miła obsługa, doradzi i podpowie, kolejny raz odwiedzam i za każdym razem jestem zadowolony. Wymiana baterii w telefonie, folia ochronna, etui, ładowarka. Dwa razy taniej niż w media... Do tego dodatkowa folia na zapas w cenie." /></SwiperSlide>
                    <SwiperSlide><Testimonial customerName="Eliza S." customerFirstLetter="E" customerOpinion="Pełen profesjonalizm! Panowie znają się na rzeczy! Ceny naprawy oraz akcesoria do telefonów na maxa konkurencyjne, porównując inne serwisy.
                            Polecam z czystym sumieniem!" /></SwiperSlide>
                    <SwiperSlide><Testimonial customerName="Michał A." customerFirstLetter="M" customerOpinion="Super obsługa, szybko zdjagnozowana awaria oraz szybko usunięta. Fachowa obsługa. Najlepszy punkt serwisowy w jakim byłem i z którego korzystałem! :)" /></SwiperSlide>                    
                    <SwiperSlide><Testimonial customerName="Irena Z." customerFirstLetter="I" customerOpinion="Jestem bardzo, zadowolona z usług. Panowie pomocni, cierpliwi i mili. Doskonale potrafią zdiagnozować usterki. Widać, że robią to co lubią. Polecam" /></SwiperSlide>                    
                    <SwiperSlide><Testimonial customerName="Halina K." customerFirstLetter="H" customerOpinion="Profesjonalne usługi, dokładność, rzetelność, miła obsługa klienta, ceny przystępne. Polecam, a na pewno się nie zawiedziecie" /></SwiperSlide>                    
                    <SwiperSlide><Testimonial customerName="Pablo" customerFirstLetter="P" customerOpinion="Dobry sklep. Chłopaki się starają, obsługa na wysokim poziomie, ceny bardzo ok. Sklep jest mnóstwo lat i pewnie jeszcze wiele przed nim. Polecam." /></SwiperSlide>                    
                    <SwiperSlide><Testimonial customerName="Michał S." customerFirstLetter="M" customerOpinion="Chłopaki konkretni i znający się na tym co robią. Brak uwag." /></SwiperSlide>                   
                    <SwiperSlide><Testimonial customerName="Paweł B." customerFirstLetter="P" customerOpinion="Znam ten serwis wiele lat i uważam że to jeden z najlepszych serwisów w Będzinie i okolicach. Więc polecam." /></SwiperSlide>                    
                    <SwiperSlide><Testimonial customerName="Maciej R." customerFirstLetter="M" customerOpinion="Gorąco polecam serwis :) Obsługa bardzo sympatyczna, a jeśli chodzi o naprawę to iście ekspresowa!!! Wymieniono mi gniazdo ładowania w telefonie w ciągu dwóch godzin za co jestem bardzo wdzięczny, ponieważ inne serwisy potrzebują dzień lub dwa na wymianę. Cena też okazała się najlepsza w okolicy" /></SwiperSlide>    
                    <SwiperSlide><Testimonial customerName="Michał" customerFirstLetter="M" customerOpinion="Mega uczciwy człowiek… Chciałem wymienić gniazdo, a Pan powiedział, że wystarczy zostawić na wyczyszczenie i będzie git. I właśnie tak było. Zaoszczędziłem spora sumkę :) Polecam" /></SwiperSlide>    
                    <SwiperSlide><Testimonial customerName="Damian W." customerFirstLetter="D" customerOpinion="Firma z wieloletnim doświadczeniem. Od kilku lat telefony komórkowe naprawiam tylko u nich. Jeśli szukasz specjalistów, którzy mają naprawić Ci telefon szybko i w rozsądnej cenie, to lepszego miejsca nie znajdziesz w centrum Będzina." /></SwiperSlide>    
                    <SwiperSlide><Testimonial customerName="Piotr S." customerFirstLetter="P" customerOpinion="Wielokrotnie korzystałem z usług serwisu, aktualnie kolejny telefon dziecka jest w serwisie i nie martwię się o naprawę. Jeśli będzie możliwa i opłacalna to naprawią. Polecam." /></SwiperSlide>    
                    
                </Swiper>
            </div>
        </main>
    );
};
 
export default Home;