import React from "react";
import Header from "../components/Header";
 
const Regulamin = () => {
    return (
        <main>
            <Header title="Regulamin" />
            <div className="regulamin-section section container-90">
                <h2>I. Postanowienia ogólne</h2>

                <p><strong>1. Definicje:</strong></p>
                <ul>
                    <li><strong>Serwis</strong> - Usługi naprawy i konserwacji sprzętu komputerowego świadczone przez PROTELKOM.</li>
                    <li><strong>Klient</strong> - Osoba fizyczna lub prawna korzystająca z usług serwisu komputerowego.</li>
                    <li><strong>Regulamin</strong> - Niniejszy regulamin określający zasady korzystania z usług serwisu komputerowego.</li>
                </ul>

                <p><strong>2. Postanowienia wstępne:</strong></p>
                <ul>
                    <li>Niniejszy regulamin obowiązuje od momentu opublikowania go na stronie internetowej serwisu komputerowego www.protelkom.pl.</li>
                    <li>Korzystanie z usług serwisu oznacza akceptację niniejszego regulaminu.</li>
                </ul>

                <h2>II. Zakres usług</h2>

                <p><strong>1. Rodzaje usług:</strong></p>
                <ul>
                    <li>Serwis oferuje naprawę i konserwację komputerów, laptopów, oraz innych urządzeń elektronicznych.</li>
                    <li>Usługi obejmują diagnostykę, naprawę sprzętu, instalację oprogramowania, oraz inne prace zgodnie z ofertą na stronie internetowej serwisu.</li>
                </ul>

                <p><strong>2. Proces realizacji usług:</strong></p>
                <ul>
                    <li>Klient zgłasza urządzenie do naprawy poprzez formularz kontaktowy, telefonicznie lub osobiście w siedzibie serwisu.</li>
                    <li>Po wstępnej diagnostyce Klient zostaje poinformowany o kosztach i czasie realizacji usługi.</li>
                    <li>Wykonanie usługi następuje po akceptacji kosztów przez Klienta.</li>
                </ul>

                <h2>III. Ceny i płatności</h2>

                <p><strong>1. Cennik usług:</strong></p>
                <ul>
                    <li>Cennik usług jest dostępny na stronie internetowej serwisu i może ulec zmianie.</li>
                    <li>W przypadku konieczności wykonania dodatkowych prac, Klient jest informowany o ich kosztach przed rozpoczęciem naprawy.</li>
                </ul>

                <p><strong>2. Sposób płatności:</strong></p>
                <ul>
                    <li>Płatność za usługę jest dokonywana gotówką, przelewem bankowym lub kartą płatniczą po zakończeniu naprawy, chyba że strony ustaliły inaczej.</li>
                </ul>

                <h2>IV. Gwarancja i reklamacje</h2>

                <p><strong>1. Gwarancja:</strong></p>
                <ul>
                    <li>Serwis udziela gwarancji na wykonane usługi oraz na wymienione części, zgodnie z obowiązującymi przepisami prawa.</li>
                    <li>Okres gwarancji wynosi [np. 3 miesiące], chyba że ustalono inaczej.</li>
                </ul>

                <p><strong>2. Reklamacje:</strong></p>
                <ul>
                    <li>Klient ma prawo do złożenia reklamacji w przypadku niewłaściwego wykonania usługi lub wadliwych części.</li>
                    <li>Reklamacje należy zgłaszać w formie pisemnej na adres e-mail lub listownie do siedziby serwisu.</li>
                    <li>Serwis zobowiązuje się do rozpatrzenia reklamacji w terminie [np. 14 dni roboczych] od daty jej otrzymania.</li>
                </ul>

                <h2>V. Odpowiedzialność</h2>

                <p><strong>1. Odpowiedzialność serwisu:</strong></p>
                <ul>
                    <li>Serwis nie ponosi odpowiedzialności za dane znajdujące się na urządzeniach przekazanych do naprawy. Klient powinien zabezpieczyć dane przed przekazaniem urządzenia do serwisu.</li>
                    <li>Serwis nie ponosi odpowiedzialności za ewentualne szkody wynikłe z opóźnień w realizacji usług, jeśli są one spowodowane okolicznościami niezależnymi od serwisu.</li>
                </ul>

                <p><strong>2. Odpowiedzialność Klienta:</strong></p>
                <ul>
                    <li>Klient zobowiązany jest do podania pełnych i prawdziwych informacji dotyczących stanu technicznego urządzenia.</li>
                    <li>Klient ponosi odpowiedzialność za dostarczenie do serwisu urządzenia, które nie zawiera legalnego oprogramowania lub jest wykorzystywane niezgodnie z prawem.</li>
                </ul>

                <h2>VI. Polityka prywatności</h2>

                <p><strong>1. Dane osobowe:</strong></p>
                <ul>
                    <li>Serwis zbiera i przetwarza dane osobowe Klientów zgodnie z obowiązującymi przepisami prawa.</li>
                </ul>

                <p><strong>2. Administrator danych osobowych:</strong></p>
                <ul>
                    <li>Administratorem czyli podmiotem decydującym o tym, jak będą wykorzystywane Państwa dane osobowe na potrzeby realizowanych usług jest PROTELKOM Sp. z o.o. z siedzibą w Będzinie 42-500, ul. J. Piłsudskiego 24 NIP: 6252491615.</li>
                </ul>

                <h2>VII. Postanowienia końcowe</h2>

                <p><strong>1. Zmiany regulaminu:</strong></p>
                <ul>
                    <li>Serwis zastrzega sobie prawo do zmiany niniejszego regulaminu. Zmiany obowiązują od momentu ich opublikowania na stronie internetowej serwisu.</li>
                </ul>

                <p><strong>2. Prawo właściwe:</strong></p>
                <ul>
                    <li>W sprawach nieuregulowanych niniejszym regulaminem zastosowanie mają przepisy prawa polskiego.</li>
                </ul>

                <p><strong>3. Rozstrzyganie sporów:</strong></p>
                <ul>
                    <li>Wszelkie spory wynikające z realizacji usług będą rozstrzygane przez sąd właściwy dla siedziby serwisu.</li>
                </ul>
            </div>
        </main>
    );
};
 
export default Regulamin;