import React from "react";

class FixedInfo extends React.Component {  
    render() {
      // This syntax ensures `this` is bound within handleClick
      return (
        <div className="fixed-info">
            <a href="tel:+48503517501"><div className="fixed-info-item"><i class="fa-solid fa-phone"></i></div></a>
            <a href="/kontakt"><div className="fixed-info-item"><i class="fa-solid fa-envelope"></i></div></a>
            <a href="https://m.me/Protelkom"><div className="fixed-info-item"><i class="fa-brands fa-facebook-messenger"></i></div></a>
        </div>
      );
    }
  }
 
export default FixedInfo;