import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const EmailForm = () => {
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: '',
    from_surname: '',
    email_id: '',
    tel: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const handleClick = () => {
    setIsClassAdded(true); // Add class after the first click
};
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_227ilha', 'template_nxrr59n', toSend, 'RdlvH6h7Ualef_5Ww')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatus('Wiadomość została wysłana!');
        setToSend({
          from_name: '',
          from_surname: '',
          email_id: '',
          tel: '',
          message: '',
        });
      })
      .catch((err) => {
        console.log('FAILED...', err);
        setStatus('Wystąpił błąd podczas wysyłania wiadomości.');
      });
  };

  return (
    <div>
      <h2>Masz pytanie?</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="input-data">
            <input type="text" name="from_name" value={toSend.from_name} onChange={handleChange} required />
            <label for="" className="required_input">Imię<span> *</span></label>
          </div>
          <div className="input-data">
            <input type="text" name="from_surname"  value={toSend.from_surname} onChange={handleChange} required/>
            <label for="" className="required_input">Nazwisko<span> *</span></label>
          </div>
        </div>
        <div className="form-row">
          <div className="input-data">
            <input type="email" name="email_id"  value={toSend.email_id} onChange={handleChange} required />                         
            <label for="" className="required_input">E-mail<span> *</span></label>
          </div>
        </div>
        <div className="form-row">
          <div className="input-data">
            <input onClick={handleClick} type="tel" name="tel" value={toSend.tel} onChange={handleChange} />                         
            <label for="" className={isClassAdded ? "optional_input" : ""} >Numer telefonu</label>
          </div>
        </div>
        <div className="form-row">
          <div className="input-data textarea">
            <textarea rows="8" cols="80" name="message" value={toSend.message} onChange={handleChange} required></textarea>
            <br />
            <label for="">Wiadomość<span> *</span></label>
            <br />
            </div>
        </div>
          
        <div className="form-row">
            <label for="RODO"><input type="checkbox" className="form-checkbox" name="RODO" required /><span> * </span>Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w celu realizacji usług dostępnych w firmie PROTELKOM Sp. z o.o. 
            <br />Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem/am poinformawany/na, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest PROTELKOM Sp. z o.o. z siedzibą w Będzinie 42-500, ul. J. Piłsudskiego 24 NIP: 6252491615.
            </label>
          </div>
        <div className="form-row">
            <button type="submit" className="submit-btn">
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <span className="button-text">Wyślij</span>
            </button>
        </div>
      </form>
      {status && <p className="formOutcome">{status}</p>}
    </div>
  );
};

export default EmailForm;